.upload {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
  margin-right:150px;
}

.content {
  display: flex;
  flex-direction: row;
  padding:2em;
  box-sizing: border-box;
  width: 100%;
}

.row {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom:4em ;
}

.uploadedImgDiv {
  & img {
    max-width:100%;
  }
}

.files {
  margin-left: 32px;
  align-items: flex-start;
  justify-items: flex-start;
  flex: 1;
  overflow-y: auto;
}

.actions {
  text-align:center;
  width: 100%;
  margin: 4em 0;

  & button {
    margin:0 1em;
  }
}