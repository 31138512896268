
@media (max-width: 600px) {
  .Images {
    margin-right:3em;
  }
}

.Images.small ul:first-child {
  display:flex;
  flex-wrap: wrap;
}

.Images.small img {
  max-height:300px;
}

.Images img {
  max-height:750px;
  min-height:100px;
  min-width:100px;
}

.Images.large .Image {
  max-width:800px;
  min-width:100px;
}

.Images.small .Image {
  width:200px;
  margin:0 3em 3em 0;
}

.Image {
  margin-bottom:3em;
}

.Image img {
  max-width:100%;
}

.Image .meta {
  margin-top:2em;
  font-size:.7em;
  font-style:italic;
}
.Image .meta ul {
  margin:0.5em 0 0 0;
  padding:0;
  list-style-type:none;
}
.Image .meta li {
  margin: 0;
  padding: 0;
}

.Image .description {
  max-width:750px;
}

.Image .mark {
  margin-top:1em;
}

.Image .interaction {
  margin:0.5em 0;
}

.nonLoggedInImage {
  
}
