body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.SiteContent a, 
.Image a {
  font-family: 'Work Sans', sans-serif;
  font-weight:normal;
  color:#888 !important;
  border:0;
  text-decoration:none;
  background:none;
  border-bottom: 2px solid #ddd;
}

.siteWrapper {
  padding:1em 1em 4em 4.3em;
}
