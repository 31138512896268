.Dropzone {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index:10000;
  pointer-events: none;
  display:none;
}
.Dropzone.over {
  display: block;
  pointer-events: auto;
  background:rgba(255, 255, 255);
}

.Dropzone .Title {
  display:block;
  margin-top:10em;
  text-align:center;
  font-weight:bold;
  pointer-events: none;
}
